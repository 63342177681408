<template>
  <Detail v-if="show" :id="id" :is-view="true" :schoolId="schoolId" />
</template>

<script>
import Detail from "./components/Detail";

export default {
  components: { Detail },
  data() {
    return {
      show: false,
      id: ""
    };
  },
  created() {
    let { id, schoolId } = this.$route.query;
    this.id = id;
    this.schoolId = schoolId;
    this.show = true;
  }
};
</script>
